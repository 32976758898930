import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { Parse } from "parse";

Parse.initialize("AWkPplHMIY9BT8RKZR7cYj6KPMLuMeJ1GMGGOjDk", "bd328hdyqbuyg2976te31eghdt7679F6F6DF7GgFG9Ff");
Parse.serverURL = 'https://api.snypinc.com/parse';

Parse.Config.get().then((config) => {
  const version = config.get('version');
  console.log(`SNYP API Version: ${version}`);
}).catch((err) => console.log(err));

UIkit.use(Icons);

navigator.serviceWorker.register(
  new URL('sw.js', import.meta.url),
  {type: 'module'}
);
